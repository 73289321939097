import React from 'react';
import T from 'i18n-react';
import Link from 'next/link';

import { Button } from '@motorway/mw-highway-code';

import { triggerSignUpNowLinkClicked } from 'Services/analytics/events';
import Content from 'Storybook/local/Content/Content';
import useIsAuth from 'Utilities/hooks/useIsAuth';
import { useVehicleListButtonConfigWithUnauthedFallback } from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';

import texts from '../../texts.json';

import styles from './SignupBar.module.scss';

T.setTexts(texts);

const SignupBar = () => {
	const isAuth = useIsAuth();
	const { as, href } = useVehicleListButtonConfigWithUnauthedFallback({ unauthorizedFallback: 'signUp' });

	return (
		<Content
			center
			className={styles.signupBar}
			element="section"
			wrapper={{
				className: styles.signupBarWrapper,
			}}
		>
			<>
				<h4 className={styles.titleMobile}>{T.translate('homePage.signupBar.titleMobile')}</h4>
				<h4 className={styles.titleTablet}>{T.translate('homePage.signupBar.titleTablet')}</h4>
				<div className={styles.buttonContainer}>
					<Link passHref as={as} href={href}>
						<Button
							fullWidth
							label={T.translate(`homePage.signupBar.buttonLabel${isAuth ? ':signin' : ''}`)}
							onClick={() => triggerSignUpNowLinkClicked('page_bottom')}
							size="large"
						/>
					</Link>
				</div>
			</>
		</Content>
	);
};

export default SignupBar;
