import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import T from 'i18n-react';

import { MakeLogo } from 'Storybook/core/MakeLogo/MakeLogo';
import Content from 'Storybook/local/Content/Content';

import texts from '../texts.json';
import { canUseWebP } from '../Utilities';

import styles from './MakesCarousel.module.scss';

T.setTexts(texts);

const makeNames = {
	audi: 'Audi',
	bmw: 'BMW',
	ford: 'Ford',
	'land-rover': 'Land Rover',
	mercedes: 'Mercedes',
	porsche: 'Porsche',
	renault: 'Renault',
	toyota: 'Toyota',
	volkswagen: 'Volkswagen',
};

const desktopMakes = [['toyota', 'renault', 'porsche', 'ford', 'bmw', 'land-rover', 'mercedes', 'volkswagen', 'audi']];

const tabletMakes = [
	['porsche', 'ford', 'bmw', 'land-rover', 'mercedes'],
	['toyota', 'renault', 'volkswagen', 'audi'],
];

const mobileMakes = [
	['ford', 'bmw', 'land-rover'],
	['toyota', 'renault', 'porsche', 'mercedes', 'volkswagen', 'audi'],
];

class MakesCarousel extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loadImages: false,
			webpSupport: false,
		};
	}

	componentDidMount() {
		if ('IntersectionObserver' in window) {
			const options = {
				rootMargin: '400px',
				threshold: 0,
			};

			this.observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					this.setState(
						{
							loadImages: true,
							webpSupport: canUseWebP(),
						},
						this.observer.disconnect(),
					);
				}
			}, options);
			this.observer.observe(ReactDOM.findDOMNode(this)); // eslint-disable-line
		} else {
			this.setState({
				loadImages: true,
			});
		}
	}

	componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	renderMakeRows = (breakpoint = 'desktop') => {
		const makeRowsMap = {
			desktop: desktopMakes,
			mobile: mobileMakes,
			tablet: tabletMakes,
		};

		const makeRows = makeRowsMap[breakpoint] || [];

		const { loadImages, webpSupport } = this.state;

		return (
			<div className={styles[`${breakpoint}Rows`]}>
				{makeRows.map((row, index) => (
					<ul key={index.toString()}>
						{row.map((slug) => (
							<li key={slug}>
								<MakeLogo
									className={cx('mw-i')}
									make={loadImages ? slug : null}
									title={makeNames[slug]}
									webp={webpSupport}
								/>
							</li>
						))}
					</ul>
				))}
			</div>
		);
	};

	render() {
		return (
			<section className={styles.makesCarousel}>
				<Content center element="h3">
					{T.translate('homePage.makesCarousel.title')}
				</Content>

				{this.renderMakeRows('desktop')}
				{this.renderMakeRows('tablet')}
				{this.renderMakeRows('mobile')}
			</section>
		);
	}
}

export default MakesCarousel;
