import React from 'react';
import PropTypes from 'prop-types';

import styles from './MobileBreak.module.scss';

const MobileBreak = ({ lineBreakCount }) => (
	<span className={styles.mobileOnly}>
		{Array(lineBreakCount)
			.fill()
			.map((item, index) => (
				<br key={index.toString()} />
			))}
	</span>
);

MobileBreak.propTypes = {
	lineBreakCount: PropTypes.number,
};

MobileBreak.defaultProps = {
	lineBreakCount: 2,
};

export default MobileBreak;
