/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import T from 'i18n-react';

import HomePage from 'Components/HomePage/HomePage';
import MetaTags from 'Components/MetaTags/MetaTags';
import PageComposer from 'Components/PageComposer/PageComposer';
import texts from 'Components/texts.json';
import { PAGE_SLUGS } from 'Utilities/consts';

T.setTexts(texts);

const IndexPage = () => (
	<>
		<MetaTags
			includeCanonicalTag
			description={T.translate('metaTags.pages.index.description')}
			title={T.translate('metaTags.pages.index.title')}
		/>

		<PageComposer pageSlug={PAGE_SLUGS.HOME}>
			<HomePage />
		</PageComposer>
	</>
);

export default IndexPage;
