import React from 'react';
import cx from 'classnames';
import T from 'i18n-react';

import useFeesStore from 'Stores/FeesStore/FeesStore';
import Content from 'Storybook/local/Content/Content';

import ImageLazy from '../ImageLazy/ImageLazy';
import texts from '../texts.json';

import step1Png from './images/how_it_works_step_1.png';
import step1Webp from './images/how_it_works_step_1.webp';
import step2Png from './images/how_it_works_step_2.png';
import step2Webp from './images/how_it_works_step_2.webp';
import step3Png from './images/how_it_works_step_3.png';
import step3Webp from './images/how_it_works_step_3.webp';
import step4Png from './images/how_it_works_step_4.png';
import step4Webp from './images/how_it_works_step_4.webp';
import step5Png from './images/how_it_works_step_5.png';
import step5Webp from './images/how_it_works_step_5.webp';

import styles from './HowItWorks.module.scss';

/* eslint-disable global-require */
const imagesByStep = [
	[step1Png, step1Webp],
	[step2Png, step2Webp],
	[step3Png, step3Webp],
	[step4Png, step4Webp],
	[step5Png, step5Webp],
];

T.setTexts(texts);

const StepRender = ([pngSource, webpSource], index) => {
	const { PROXY_BID_INCREMENT } = useFeesStore();
	const stepIndex = index + 1;

	return (
		<div key={`step${stepIndex}`} className={cx(styles.stepRow, styles[`stepRow${stepIndex}`])}>
			<div className={styles.leftBlock}>
				<ImageLazy altText={`Step ${stepIndex}`} pngSource={pngSource} webpSource={webpSource} />
			</div>
			<div className={styles.rightBlock}>
				<span>{stepIndex}</span>
				<h3>{T.translate(`homePage.howItWorks.step${stepIndex}.title`)}</h3>
				<p>{T.translate(`homePage.howItWorks.step${stepIndex}.description`, { value: PROXY_BID_INCREMENT })}</p>
			</div>
		</div>
	);
};

const ImagesSteps = () => imagesByStep.map(StepRender);

const HowItWorks = () => (
	<>
		<div className={styles.anchorLink} id="howitworks" rel="help" />
		<Content className={styles.section} element="section" wrapper={{ className: styles.sectionWrapper }}>
			<h2>{T.translate('homePage.howItWorks.title')}</h2>
			<div className={styles.stepsWrapper}>
				<ImagesSteps />
			</div>
		</Content>
	</>
);
export default HowItWorks;
